<template>
  <v-divider
    v-if="!isMobile"
    :class="[
      pdfType ? 'mb-4 mt-2' : 'mb-8 mt-4',
      pdfType === 'print' ? 'grey' : 'white',
    ]"
    >&nbsp;</v-divider
  >
</template>

<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      required: false,
    },
    isMobile: {
      type: Boolean,
    },
    pdfType: {
      type: String,
    },
  },
};
</script>
